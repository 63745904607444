import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import GuestGuard from 'components/GuestGuard';

import { Routes } from './interfaces';

const authRoutes: Routes = [
  {
    path: '*',
    routes: [
      {
        exact: true,
        guard: GuestGuard,
        path: '/',
        component: lazy(() => import('pages/Login'))
      },
      {
        exact: true,
        path: '/login',
        component: () => <Redirect to="/" />
      }
    ]
  }
];

export { authRoutes };
