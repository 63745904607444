import React, { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

import { Collapse, ListItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';

import { useStyles } from './styles';

interface NavItemProps {
  children?: React.ReactNode;
  depth: number;
  href?: string;
  // at this point, I could not determine with type of icon and info are
  // so as any, they can be any component
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  info?: any;
  open?: boolean;
  title?: string;
}

const NavItem: React.FC<NavItemProps> = ({
  children,
  depth,
  href,
  icon: Icon,
  info: Info,
  open: openProp,
  title,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean | undefined>(openProp);

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    return (
      <ListItem className={classes.item} disableGutters key={title} {...rest}>
        <Button className={classes.button} onClick={handleToggle} style={style} variant="text">
          {Icon && <Icon className={classes.icon} size="20" />}
          <span className={classes.title}>{title}</span>
          {open ? <ExpandLess /> : <ExpandMore />}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem className={classes.itemLeaf} disableGutters key={title} {...rest}>
      <Button
        activeClassName={classes.active}
        className={clsx(classes.buttonLeaf, `depth-${depth}`)}
        component={RouterLink}
        exact
        style={style}
        to={href || '#'}
        variant="text"
      >
        {Icon && <Icon className={classes.icon} size="20" />}
        <span className={classes.title}>{title}</span>
        {Info && <Info />}
      </Button>
    </ListItem>
  );
};

NavItem.defaultProps = {
  open: false
};
export default NavItem;
