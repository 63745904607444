import React from 'react';
import { Redirect } from 'react-router-dom';

import useAuth from 'hooks/useAuth';

const GuestGuard: React.FC = ({ children }) => {
  const { signed } = useAuth();

  if (signed) {
    return <Redirect to="/app/dashboard" />;
  }

  return <>{children}</>;
};

export default GuestGuard;
