import { createApi } from '@reduxjs/toolkit/query/react';
import { Amcrew } from 'entities/Amcrew';
import { ApiResponse, baseQuery, Filter } from 'services/api';

export const amcrewApi = createApi({
  reducerPath: 'amcrews',
  baseQuery,
  tagTypes: ['Amcrews'],
  endpoints: (builder) => ({
    getAmcrews: builder.query<ApiResponse<Amcrew[]>, Filter>({
      query: (filters) => ({
        url: '/v1/amcrew/all',
        params: filters
      }),
      providesTags: (result) =>
        result
          ? [
              { type: 'Amcrews' as const, id: 'LIST' },
              ...result.data.map(({ amcrewNum }) => ({ type: 'Amcrews' as const, amcrewNum }))
            ]
          : ['Amcrews']
    }),
    getAmcrewById: builder.query<ApiResponse<Amcrew>, string>({
      query: (id) => `/v1/amcrew?num=${id}`,
      providesTags: (result, error, id) => [{ type: 'Amcrews', id }]
    }),
    addAmcrew: builder.mutation<ApiResponse<Amcrew>, Partial<Amcrew>>({
      query: (amcrew) => ({
        url: '/v1/amcrew',
        method: 'POST',
        body: amcrew
      }),
      invalidatesTags: [{ type: 'Amcrews', id: 'LIST' }]
    }),
    updateAmcrew: builder.mutation<ApiResponse<Amcrew>, Partial<Amcrew>>({
      query: (amcrew) => ({
        url: '/v1/amcrew',
        method: 'PUT',
        body: amcrew
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Amcrews', id: arg.amcrewId },
        { type: 'Amcrews', id: 'LIST' }
      ]
    })
  })
});

export const { useGetAmcrewsQuery, useGetAmcrewByIdQuery, useAddAmcrewMutation, useUpdateAmcrewMutation } = amcrewApi;
