import React from 'react';
import { Link } from 'react-router-dom';

import { AppBar, Box, Hidden, IconButton, Toolbar } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import Logo from 'components/Logo';

import { useStyles } from './styles';

import Account from '../Account';
import Notifications from '../Notifications';

interface TopBarProps {
  onMobileNavOpen?: () => void;
}

const TopBar: React.FC<TopBarProps> = ({ onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={classes.root} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton onClick={onMobileNavOpen}>
            <Menu />
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <Link to="/">
            <Logo />
          </Link>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        <Notifications />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
