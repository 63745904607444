import { createApi } from '@reduxjs/toolkit/query/react';
import { Company } from 'entities/Company';
import { ApiResponse, baseQuery, Filter } from 'services/api';

export const companyApi = createApi({
  reducerPath: 'companies',
  baseQuery,
  tagTypes: ['Companies'],
  endpoints: (builder) => ({
    getCompanies: builder.query<ApiResponse<Company[]>, Filter>({
      query: (filters) => ({
        url: '/v1/company/all',
        params: filters
      }),
      providesTags: (result) =>
        result
          ? [
              { type: 'Companies' as const, id: 'LIST' },
              ...result.data.map(({ companyId }) => ({ type: 'Companies' as const, companyId }))
            ]
          : ['Companies']
    }),
    getCompanyById: builder.query<ApiResponse<Company>, string>({
      query: (id) => `/v1/company?id=${id}`,
      providesTags: (result, error, id) => [{ type: 'Companies', id }]
    }),
    addCompany: builder.mutation<ApiResponse<Company>, Partial<Company>>({
      query: (company) => ({
        url: '/v1/company',
        method: 'POST',
        body: company
      }),
      invalidatesTags: ['Companies']
    }),
    updateCompany: builder.mutation<ApiResponse<Company>, Partial<Company>>({
      query: (company) => ({
        url: '/v1/company',
        method: 'PUT',
        body: company
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Companies', id: arg.companyId },
        { type: 'Companies', id: 'LIST' }
      ]
    })
  })
});

export const { useGetCompaniesQuery, useGetCompanyByIdQuery, useAddCompanyMutation, useUpdateCompanyMutation } =
  companyApi;
