import { createApi } from '@reduxjs/toolkit/query/react';
import { Storeroom } from 'entities/Storeroom';
import { ApiResponse, baseQuery, Filter } from 'services/api';

export const storeroomApi = createApi({
  reducerPath: 'storerooms',
  baseQuery,
  tagTypes: ['Storerooms'],
  endpoints: (builder) => ({
    getStorerooms: builder.query<ApiResponse<Storeroom[]>, Filter>({
      query: (filters) => ({
        url: '/v1/storeroom/all',
        params: filters
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ storeroomId }) => ({ type: 'Storerooms' as const, storeroomId })),
              { type: 'Storerooms' as const, id: 'LIST' }
            ]
          : ['Storerooms']
    }),
    addStoreroom: builder.mutation<ApiResponse<Storeroom>, Partial<Storeroom>>({
      query: (storeroom) => ({
        url: '/v1/storeroom',
        method: 'POST',
        body: storeroom
      }),
      invalidatesTags: ['Storerooms']
    })
  })
});

export const { useGetStoreroomsQuery, useAddStoreroomMutation } = storeroomApi;

export default storeroomApi.reducer;
