import { createApi } from '@reduxjs/toolkit/query/react';
import { Item } from 'entities/Item';
import { ApiResponse, baseQuery, Filter } from 'services/api';

export const itemApi = createApi({
  reducerPath: 'items',
  baseQuery,
  tagTypes: ['Items', 'ItemByName'],
  endpoints: (builder) => ({
    getItems: builder.query<ApiResponse<Item[]>, Filter>({
      query: (filters) => ({
        url: '/v1/item/all',
        params: filters
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ itemId }) => ({
                type: 'Items' as const,
                itemId
              })),
              { type: 'Items' as const, id: 'LIST' }
            ]
          : ['Items']
    }),
    getItemById: builder.query<ApiResponse<Item>, string>({
      query: (id) => `/v1/item?id=${id}`,
      providesTags: (result, error, id) => [{ type: 'Items', id }]
    }),
    getItemByName: builder.query<Item[], string>({
      query: (name) => {
        const filter: Filter = {
          filter: `[{"field":"itemNum","operation":"like","value":"${name}"}]`
        };
        return {
          url: '/v1/item/all',
          params: filter
        };
      },
      transformResponse: (result: ApiResponse<Item[]>) => result.data,
      providesTags: (result) =>
        result ? [...result.map(({ itemNum }) => ({ type: 'ItemByName' as const, itemNum }))] : ['ItemByName']
    }),
    addItem: builder.mutation<ApiResponse<Item>, Partial<Item>>({
      query: (item) => ({
        url: '/v1/item',
        method: 'POST',
        body: item
      }),
      invalidatesTags: ['Items']
    }),
    updateItem: builder.mutation<ApiResponse<Item>, Partial<Item>>({
      query: (item) => ({
        url: '/v1/item',
        method: 'PUT',
        body: item
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Items', id: arg.itemId },
        { type: 'Items', id: 'LIST' }
      ]
    })
  })
});

export const {
  useGetItemsQuery,
  useGetItemByIdQuery,
  useGetItemByNameQuery,
  useAddItemMutation,
  useUpdateItemMutation
} = itemApi;
