import React, { useEffect } from 'react';

import { Box, LinearProgress } from '@material-ui/core';
import clsx from 'clsx';
import NProgress from 'nprogress';

import { useStyles } from './styles';

interface Props {
  className?: string;
}

const LoadingScreen: React.FC<Props> = ({ className }) => {
  const classes = useStyles();

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <div className={clsx(className, classes.root)}>
      <Box width={400}>
        <LinearProgress />
      </Box>
    </div>
  );
};

export default LoadingScreen;
