import { combineReducers } from '@reduxjs/toolkit';
import { notificationMiddleware } from 'middleware/NotificationMiddleware';
import editing from 'store/ducks/editing';

import { amcrewApi } from './amcrew';
import { assetApi } from './asset';
import { assetHistoryApi } from './assetHistory';
import { companyApi } from './company';
import { craftApi } from './craft';
import { itemApi } from './item';
import { jobplanApi } from './jobplan';
import { laborApi } from './labor';
import { locationApi } from './location';
import { preventivePlanApi } from './preventivePlan';
import { statusListApi } from './statusList';
import { storeroomApi } from './storeroom';
import users from './user';
import { valueListApi } from './valueList';

export default combineReducers({
  [amcrewApi.reducerPath]: amcrewApi.reducer,
  [assetApi.reducerPath]: assetApi.reducer,
  [assetHistoryApi.reducerPath]: assetHistoryApi.reducer,
  [craftApi.reducerPath]: craftApi.reducer,
  [companyApi.reducerPath]: companyApi.reducer,
  [craftApi.reducerPath]: craftApi.reducer,
  [itemApi.reducerPath]: itemApi.reducer,
  [jobplanApi.reducerPath]: jobplanApi.reducer,
  [laborApi.reducerPath]: laborApi.reducer,
  [locationApi.reducerPath]: locationApi.reducer,
  [preventivePlanApi.reducerPath]: preventivePlanApi.reducer,
  [statusListApi.reducerPath]: statusListApi.reducer,
  [storeroomApi.reducerPath]: storeroomApi.reducer,
  users,
  [valueListApi.reducerPath]: valueListApi.reducer,
  editing
});

export const middlewares = [
  notificationMiddleware,
  amcrewApi.middleware,
  assetApi.middleware,
  assetHistoryApi.middleware,
  craftApi.middleware,
  companyApi.middleware,
  craftApi.middleware,
  itemApi.middleware,
  jobplanApi.middleware,
  laborApi.middleware,
  locationApi.middleware,
  preventivePlanApi.middleware,
  statusListApi.middleware,
  storeroomApi.middleware,
  valueListApi.middleware
];
