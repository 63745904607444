import React, { createContext, useState } from 'react';

import { User } from 'entities/User';
import { useAppDispatch } from 'hooks/useAppStore';
import jwtDecode from 'jwt-decode';
import api from 'services/api';
import * as auth from 'services/auth';
import { addUser } from 'store/ducks/user';
import { AxiosError } from 'axios';

interface AuthContextData {
  signed: boolean;
  user: User | null;
  error: boolean;
  message: string;
  signIn(email: string, password: string): Promise<void>;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');

  async function signIn(email: string, password: string) {
    try {
      const response = await auth.signIn(email, password);
      const { data } = response;
      const extractedToken = jwtDecode<auth.KeycloadJWT>(data.access_token);
      const respUser: User = {
        name: extractedToken.name,
        givenName: extractedToken.given_name,
        familyName: extractedToken.family_name,
        email: extractedToken.email,
        avatar: 'https://i.pravatar.cc/150?img=49', // TODO: how can we get this?
        groups: extractedToken.group_member.reduce((acc: string[], item: string) => {
          if (!item.startsWith('/_tenant_')) {
            acc.push(item.replace('/', ''));
          }
          return acc;
        }, []),
        tenantid: extractedToken.tenantid,
        company: extractedToken.company,
        token: data.access_token
      };
      setError(false);
      setUser(respUser);

      api.defaults.headers.Authorization = `Bearer ${data.access_token}`;

      dispatch(addUser(respUser));
    } catch (e) {
      const { response } = e as AxiosError;

      if (response?.status !== 200) {
        setUser(null);
        setError(true);
        setMessage('Usuário inválido');
      }
    }
  }

  function signOut() {
    setUser(null);
  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        user,
        error,
        message,
        signIn,
        signOut
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
