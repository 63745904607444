import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      filter: 'brightness(0) invert(1)'
    },
    [theme.breakpoints.up('xl')]: {
      filter: 'brightness(0) invert(1)'
    }
  }
}));
