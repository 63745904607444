import React from 'react';
import ReactDOM from 'react-dom';

import 'react-perfect-scrollbar/dist/css/styles.css';
import { enableES5 } from 'immer';
import * as serviceWorker from 'serviceWorker';

import App from './App';

enableES5();

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.register();
