// eslint disabled due to keycloak response format
/* eslint-disable camelcase */
import axios, { AxiosError, AxiosResponse } from 'axios';
import { JwtPayload } from 'jwt-decode';

export interface KeycloadJWT extends JwtPayload {
  resource_access: {
    realm: {
      roles: string[];
    };
  };
  group_member: string[];
  tenantid: string;
  company: string;
  name: string;
  email: string;
  given_name: string;
  family_name: string;
}

interface Response extends AxiosResponse {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  token_type: string;
  id_token: string;
  session_state: string;
  scope: string;
}

const keycloakApi = axios.create({
  baseURL: `${process.env.REACT_APP_KEYCLOAK_AUTH_URI}/realms/${process.env.REACT_APP_KEYCLOAK_AUTH_REALM}`
});

export function signIn(email: string, password: string) {
  const params = new URLSearchParams();
  params.append('client_secret', process.env.REACT_APP_KEYCLOAK_AUTH_CLIENT_SECRECT || '');
  params.append('username', email);
  params.append('password', password);
  params.append('grant_type', 'password');
  params.append('client_id', process.env.REACT_APP_KEYCLOAK_AUTH_CLIENT_ID || 'pcm-app');

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  };

  return keycloakApi
    .post<Response>('/protocol/openid-connect/token', params, config)
    .then((res) => res)
    .catch((error: AxiosError) => Promise.reject(error.response));
}

export default keycloakApi;
