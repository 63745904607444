import { createApi } from '@reduxjs/toolkit/query/react';
import { ListValues } from 'entities/ValueList';
import { ApiResponse, baseQuery } from 'services/api';

export const valueListApi = createApi({
  reducerPath: 'valueListApi',
  baseQuery,
  tagTypes: ['ListValues'],
  endpoints: (builder) => ({
    getListByName: builder.query<ApiResponse<ListValues[]>, string>({
      query: (listName) => `/v1/valuelist?listName=${listName}`,
      keepUnusedDataFor: 3600, // 1h
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ valueListId }) => ({ type: 'ListValues' as const, valueListId })),
              { type: 'ListValues' as const, id: 'LIST' }
            ]
          : ['ListValues']
    })
  })
});

export const { useGetListByNameQuery } = valueListApi;

export default valueListApi.reducer;
