import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { User } from 'entities/User';

const name = 'user';

const userAdapter = createEntityAdapter<User>({
  selectId: (value) => value.email
});

const userSlice = createSlice({
  name,
  initialState: userAdapter.getInitialState(),
  reducers: {
    addUser: userAdapter.addOne
  }
});

export const { addUser } = userSlice.actions;

export default userSlice.reducer;
