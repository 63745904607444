import React from 'react';
import { Redirect } from 'react-router-dom';

import useAuth from 'hooks/useAuth';

const AuthGuard: React.FC = ({ children }) => {
  const { signed } = useAuth();

  if (!signed) {
    return <Redirect to="/login" />;
  }

  return <>{children}</>;
};

export default AuthGuard;
