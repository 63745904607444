import React, { useRef, useState } from 'react';

import { Box, IconButton, Popover, Tooltip, Typography } from '@material-ui/core';
import { Notifications as NotificationBell } from '@material-ui/icons';

import { useStyles } from './styles';

const Notifications: React.FC = () => {
  const classes = useStyles();

  const iconButtonRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Notificações">
        <IconButton ref={iconButtonRef} onClick={handleOpen}>
          <NotificationBell />
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={iconButtonRef.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2}>
          <Typography variant="h5" color="textPrimary">
            Notificações
          </Typography>
        </Box>
        <Box p={2}>
          <Typography variant="h6" color="textPrimary">
            Sem novas Notificações
          </Typography>
        </Box>
      </Popover>
    </>
  );
};

export default Notifications;
