import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ConfirmOptions } from 'contexts/ConfirmContext/types';

interface Props {
  open: boolean;
  options: ConfirmOptions;
  onCancel: () => void;
  onConfirm: () => void;
  onClose: () => void;
}

const ConfirmationDialog: React.FC<Props> = ({ open, options, onCancel, onConfirm, onClose }) => {
  const {
    title,
    description,
    customBody,
    confirmationText,
    cancellationText,
    dialogProps,
    confirmationButtonProps,
    cancellationButtonProps
  } = options;

  return (
    <Dialog fullWidth {...dialogProps} open={open} onClose={onClose}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{!customBody ? <DialogContentText>{description}</DialogContentText> : description}</DialogContent>
      {(!confirmationButtonProps?.hidden || !cancellationButtonProps?.hidden) && (
        <DialogActions>
          {!cancellationButtonProps?.hidden && (
            <Button variant="outlined" {...cancellationButtonProps} onClick={onCancel}>
              {cancellationText}
            </Button>
          )}
          {!confirmationButtonProps?.hidden && (
            <Button color="primary" {...confirmationButtonProps} onClick={onConfirm}>
              {confirmationText}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ConfirmationDialog;
