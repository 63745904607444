import React from 'react';

import { makeStyles as makeMuiStyles } from '@material-ui/core/styles';
import * as notistack from 'notistack';

export interface Notifications {
  enqueueErrorNotification: (errorMessage: string) => void;
  enqueueNotification: (message: string, type: notistack.VariantType) => void;
}

export const useNotifications = (): Notifications => {
  const snackbar = notistack.useSnackbar();

  return {
    enqueueErrorNotification: (errorMessage) => {
      snackbar.enqueueSnackbar(errorMessage, {
        variant: 'error'
      });
    },
    enqueueNotification: (message, type) => {
      snackbar.enqueueSnackbar(message, {
        variant: type
      });
    }
  };
};

// Notifications available outside components,
// based on https://github.com/iamhosseindhv/notistack/issues/30#issuecomment-542863653
let globallyStoredSnackbar: notistack.ProviderContext | undefined;
export const globallyAvailableNotifications: Notifications = {
  enqueueErrorNotification: (errorMessage) => {
    globallyStoredSnackbar?.enqueueSnackbar(errorMessage, {
      variant: 'error'
    });
  },
  enqueueNotification: (message, type) => {
    globallyStoredSnackbar?.enqueueSnackbar(message, {
      variant: type
    });
  }
};

export const NotificationsProvider: React.FC = ({ children }) => {
  const classes = useStyles();

  const GloballyStoredSnackbarProvider: React.FC = React.useMemo(
    () => () => {
      globallyStoredSnackbar = notistack.useSnackbar();
      return null;
    },
    []
  );

  return (
    <notistack.SnackbarProvider
      maxSnack={3}
      autoHideDuration={5000}
      hideIconVariant
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      classes={{
        variantError: classes.error
      }}
    >
      <GloballyStoredSnackbarProvider />
      {children}
    </notistack.SnackbarProvider>
  );
};

const useStyles = makeMuiStyles(() => ({
  error: {
    backgroundColor: 'rgb(255, 0, 0) !important'
  }
}));
