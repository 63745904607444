import { createApi } from '@reduxjs/toolkit/query/react';
import { JobPlanDto } from 'entities/JobPlan';
import { ApiResponse, baseQuery, Filter } from 'services/api';

export const jobplanApi = createApi({
  baseQuery,
  reducerPath: 'jobplan',
  tagTypes: ['JobPlans', 'JobTasks', 'JobLabors', 'JobMaterials'],
  endpoints: (builder) => ({
    getJobPlans: builder.query<ApiResponse<JobPlanDto[]>, Filter>({
      query: (filters) => ({
        url: '/v1/jobplan/all',
        params: filters
      }),
      providesTags: (result) =>
        result
          ? [
              { type: 'JobPlans' as const, id: 'LIST' },
              ...result.data.map(({ jobPlanId }) => ({ type: 'JobPlans' as const, jobPlanId }))
            ]
          : ['JobPlans']
    }),
    getJobPlanById: builder.query<ApiResponse<JobPlanDto>, string>({
      query: (id) => `/v1/jobplan?id=${id}`,
      providesTags: (result, error, id) => [{ type: 'JobPlans', id }]
    }),
    addJobPlan: builder.mutation<ApiResponse<JobPlanDto>, Partial<JobPlanDto>>({
      query: (jobplan) => ({
        url: '/v1/jobplan',
        method: 'POST',
        body: jobplan
      }),
      invalidatesTags: [{ type: 'JobPlans', id: 'LIST' }]
    }),
    updateJobPlan: builder.mutation<ApiResponse<JobPlanDto>, Partial<JobPlanDto>>({
      query: (jobplan) => ({
        url: '/v1/jobplan',
        method: 'PUT',
        body: jobplan
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'JobPlans', id: arg.jobPlanId },
        { type: 'JobPlans', id: 'LIST' }
      ]
    })
  })
});

export const { useGetJobPlansQuery, useGetJobPlanByIdQuery, useAddJobPlanMutation, useUpdateJobPlanMutation } =
  jobplanApi;
