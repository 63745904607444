import { createApi } from '@reduxjs/toolkit/query/react';
import { Asset } from 'entities/Asset';
import { ApiResponse, baseQuery, Filter } from 'services/api';

export const assetApi = createApi({
  reducerPath: 'assets',
  baseQuery,
  tagTypes: ['Assets'],
  endpoints: (builder) => ({
    getAssets: builder.query<ApiResponse<Asset[]>, Filter>({
      query: (filters) => ({
        url: '/v1/asset/all',
        params: filters
      }),
      providesTags: (result) =>
        result
          ? [
              { type: 'Assets' as const, id: 'LIST' },
              ...result.data.map(({ assetId }) => ({ type: 'Assets' as const, assetId }))
            ]
          : ['Assets']
    }),
    getAssetById: builder.query<ApiResponse<Asset>, string>({
      query: (id) => `/v1/asset?id=${id}`,
      providesTags: (result, error, id) => [{ type: 'Assets', id }]
    }),
    addAsset: builder.mutation<ApiResponse<Asset>, Partial<Asset>>({
      query: (asset) => ({
        url: '/v1/asset',
        method: 'POST',
        body: asset
      }),
      invalidatesTags: [{ type: 'Assets', id: 'LIST' }]
    }),
    updateAsset: builder.mutation<ApiResponse<Asset>, Partial<Asset>>({
      query: (asset) => ({
        url: '/v1/asset',
        method: 'PUT',
        body: asset
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Assets', id: arg.assetId },
        { type: 'Assets', id: 'LIST' }
      ]
    }),
    moveAsset: builder.mutation<
      ApiResponse<Asset>,
      {
        assetNum: string | undefined;
        newLocation: string | undefined | null;
        newParentNum: string | undefined | null;
      }
    >({
      query: ({ assetNum, newLocation, newParentNum }) => ({
        url: `/v1/asset/moveAsset?assetNum=${assetNum}&newLocation=${newLocation}&newParentNum=${newParentNum}`,
        method: 'POST'
      }),
      invalidatesTags: [{ type: 'Assets', id: 'LIST' }]
    }),
    changeAssetStatus: builder.mutation<
      ApiResponse<Asset>,
      {
        assetNum: string | undefined;
        newStatus: string | undefined;
      }
    >({
      query: ({ assetNum, newStatus }) => ({
        url: `/v1/asset/changeStatus?assetNum=${assetNum}&newStatus=${newStatus}`,
        method: 'POST'
      }),
      invalidatesTags: [{ type: 'Assets', id: 'LIST' }]
    })
  })
});

export const {
  useGetAssetsQuery,
  useGetAssetByIdQuery,
  useAddAssetMutation,
  useUpdateAssetMutation,
  useChangeAssetStatusMutation,
  useMoveAssetMutation
} = assetApi;

export default assetApi.reducer;
