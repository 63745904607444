import { createEntityAdapter, createSlice, PayloadAction, Update } from '@reduxjs/toolkit';
import { Asset } from 'entities/Asset';
import { Company } from 'entities/Company';
import { EntityType } from 'entities/EditingEntity';
import { Item } from 'entities/Item';
import { JobLabor } from 'entities/JobLabor';
import { JobMaterial } from 'entities/JobMaterial';
import { JobPlan } from 'entities/JobPlan';
import { JobTask } from 'entities/JobTask';
import { Location } from 'entities/Location';
import { Storeroom } from 'entities/Storeroom';
import { User } from 'entities/User';
import { RootState } from 'store/type';

function getEntityAdapter(entityId: string) {
  return createEntityAdapter({
    selectId: () => entityId
  });
}

interface PayloadEditing {
  entityType: EntityType;
  entityId: string;
  entityData: unknown;
}
type PayloadStartEditing = PayloadEditing;

type PayloadAddToEditing = PayloadEditing;

interface PayloadUpdateEditing extends Pick<PayloadEditing, 'entityType' | 'entityId'> {
  entityData: Partial<unknown> | Array<unknown>;
}

type PayloadEndEditing = Pick<PayloadEditing, 'entityType' | 'entityId'>;

type PayloadCurrentEditing = Pick<PayloadEditing, 'entityType' | 'entityId'>;

const assetEntityAdapter = createEntityAdapter<Asset>();
const companyEntityAdapter = createEntityAdapter<Company>();
const itemEntityAdapter = createEntityAdapter<Item>();
const joblaborEntityAdapter = createEntityAdapter<JobLabor>();
const jobmaterialEntityAdapter = createEntityAdapter<JobMaterial>();
const jobplanEntityAdapter = createEntityAdapter<JobPlan>();
const jobtaskEntityAdapter = createEntityAdapter<JobTask>();
const locationEntityAdapter = createEntityAdapter<Location>();
const storeroomEntityAdapter = createEntityAdapter<Storeroom>();
const userEntityAdapter = createEntityAdapter<User>();

const initialState = {
  asset: assetEntityAdapter.getInitialState(),
  company: companyEntityAdapter.getInitialState(),
  item: itemEntityAdapter.getInitialState(),
  joblabor: joblaborEntityAdapter.getInitialState(),
  jobmaterial: jobmaterialEntityAdapter.getInitialState(),
  jobplan: jobplanEntityAdapter.getInitialState(),
  jobtask: jobtaskEntityAdapter.getInitialState(),
  location: locationEntityAdapter.getInitialState(),
  storeroom: storeroomEntityAdapter.getInitialState(),
  user: userEntityAdapter.getInitialState(),
  dialogs: {},
  currentEditing: [] as PayloadCurrentEditing[]
};

const editingSlice = createSlice({
  name: 'editing',
  initialState,
  reducers: {
    setEditing: (state, { payload }: PayloadAction<PayloadStartEditing>) => {
      getEntityAdapter(payload.entityId).upsertOne(state[payload.entityType], payload.entityData);
    },
    addEntityEditing: (state, { payload }: PayloadAction<PayloadAddToEditing>) => {
      if (Object.values(state[payload.entityType].entities).length === 0) {
        getEntityAdapter(payload.entityId).addOne(state[payload.entityType], payload.entityData);
      } else if (Array.isArray(payload.entityData)) {
        getEntityAdapter(payload.entityId).upsertMany(state[payload.entityType], [payload.entityData]);
      } else {
        getEntityAdapter(payload.entityId).addOne(state[payload.entityType], payload.entityData);
      }
    },
    updateEntityEditing: (state, { payload }: PayloadAction<PayloadUpdateEditing>) => {
      if (Object.values(state[payload.entityType].entities).length === 0) {
        getEntityAdapter(payload.entityId).addOne(state[payload.entityType], payload.entityData);
      } else {
        const update: Update<unknown> = {
          id: payload.entityId,
          changes: payload.entityData
        };
        getEntityAdapter(payload.entityId).updateOne(state[payload.entityType], update);
      }
    },
    updateEntityField: (state, { payload }: PayloadAction<PayloadUpdateEditing>) => {
      const update: Update<unknown> = {
        id: payload.entityId,
        changes: payload.entityData
      };
      getEntityAdapter(payload.entityId).updateOne(state[payload.entityType], update);
    },
    endEditing: (state, { payload }: PayloadAction<PayloadEndEditing>) => {
      getEntityAdapter(payload.entityId).removeOne(state[payload.entityType], payload.entityId);
    },
    setCurrentEditing: (state, { payload }: PayloadAction<PayloadCurrentEditing>) => {
      if (payload.entityId) {
        state.currentEditing.push(payload);
      }
    },
    clearCurrentEditing: (state, { payload }: PayloadAction<PayloadCurrentEditing>) => {
      const index = state.currentEditing.findIndex(
        (item) => item.entityType === payload.entityType && item.entityId === payload.entityId
      );
      if (index > -1) {
        state.currentEditing.splice(index, 1);
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setToDialogData: (state, { payload }: PayloadAction<any>) => {
      state.dialogs = payload;
    },
    clearFromDialogData: (state) => {
      state.dialogs = initialState.dialogs;
    }
  }
});

export const getEditingEntityByEntityType = (state: RootState, entityType: EntityType) => state.editing[entityType];

export const getEditingEntities = (state: RootState) => state.editing;

/**
 * Leaving here for a documentation proposal
 * In this case, the Selector will not memoize the first 2 params and will not have
 * performance benefits
 *
 * This will always compare all three arguments and throw away the cache once one
 * of those arguments changes, so probably pretty much all the time, which will make
 * it slower than not doing that in the first place
 */
// const editingEntityById = (_state:RootState, _entityType: unknown, id: string) => id;

// const getItemEntityById = createSelector(
//   [editingEntity, editingEntityById],
//   (item, id) => item.entities[id],
// );

// const getItem = (state: RootState, type: EntityType, id: string) => {
//   const itemEntities = editingEntity(state, type);
//   return itemEntities.entities[id];
// }
// another way to do this
// const getItem = createSelector(
//   editingEntity,
//   (ignore, ignoreToo, gimme) => gimme,
//   (itemEntities, id) => itemEntities.entities[id],
// );

/**
 * Get an editing item by its ID
 * @param state RootState
 * @param type String value with one of editing items key
 * @param id Editing Entity that is being edited
 * @returns Enditing Entity Object
 */
export const getEditingEntityById = (state: RootState, type: EntityType, id: string | undefined) => {
  if (id) {
    const editingItem = getEditingEntityByEntityType(state, type);
    return editingItem.entities[id];
  }
  return null;
};

export const {
  endEditing,
  setEditing,
  addEntityEditing,
  updateEntityEditing,
  updateEntityField,
  setCurrentEditing,
  clearCurrentEditing,
  setToDialogData,
  clearFromDialogData
} = editingSlice.actions;

export default editingSlice.reducer;
