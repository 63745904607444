import { createApi } from '@reduxjs/toolkit/query/react';
import { Labor } from 'entities/Labor';
import { ApiResponse, baseQuery, Filter } from 'services/api';

export const laborApi = createApi({
  reducerPath: 'laborApi',
  baseQuery,
  tagTypes: ['Labors'],
  endpoints: (builder) => ({
    getLabors: builder.query<ApiResponse<Labor[]>, Filter>({
      query: (filters) => ({
        url: '/v1/labor/all',
        params: filters
      }),
      providesTags: (result) =>
        result
          ? [
              { type: 'Labors' as const, id: 'LIST' },
              ...result.data.map(({ laborId }) => ({ type: 'Labors' as const, laborId }))
            ]
          : ['Labors']
    }),
    getLaborById: builder.query<ApiResponse<Labor>, string>({
      query: (id) => `/v1/labor?id=${id}`,
      providesTags: (result, error, id) => [{ type: 'Labors', id }]
    }),
    addLabor: builder.mutation<ApiResponse<Labor>, Partial<Labor>>({
      query: (labor) => ({
        url: '/v1/labor',
        method: 'POST',
        body: labor
      }),
      invalidatesTags: [{ type: 'Labors', id: 'LIST' }]
    }),
    updateLabor: builder.mutation<ApiResponse<Labor>, Partial<Labor>>({
      query: (labor) => ({
        url: 'v1/labor',
        method: 'PUT',
        body: labor
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Labors', id: arg.laborId },
        { type: 'Labors', id: 'LIST' }
      ]
    })
  })
});

export const { useGetLaborsQuery, useGetLaborByIdQuery, useAddLaborMutation, useUpdateLaborMutation } = laborApi;

export default laborApi.reducer;
