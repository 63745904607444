import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { Avatar, Box, ButtonBase, Hidden, Menu, MenuItem, Typography } from '@material-ui/core';
import useAuth from 'hooks/useAuth';

import { useStyles } from './styles';

const Account: React.FC = () => {
  const classes = useStyles();
  const { signOut, user } = useAuth();
  // needed until material-ui v5
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const refBoxUser = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = (): void => {
    handleClose();
    signOut();
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        // correction in material-ui v5
        // https://github.com/mui-org/material-ui/issues/17010
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ref={refBoxUser}
      >
        <Avatar alt="User" className={classes.avatar} src={user?.avatar} />
        <Hidden smDown>
          <Typography variant="h6" color="inherit">
            {user?.name}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={refBoxUser.current}
        open={isOpen}
      >
        <MenuItem component={Link} to="/app/social/profile">
          Meu Perfil?
        </MenuItem>
        <MenuItem component={Link} to="/app/account">
          Minha Conta?
        </MenuItem>
        <MenuItem onClick={handleLogout}>Sair</MenuItem>
      </Menu>
    </>
  );
};

export default Account;
