import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import axios from 'axios';
import { VariantType } from 'notistack';
import { RootState } from 'store/type';

export interface ApiMessages {
  text: string;
  type: VariantType;
}

export interface ApiResponse<T = unknown> {
  success: boolean;
  errorCode: string;
  userShould: string;
  messages: ApiMessages[];
  recordCount: number;
  data: T;
}

export enum RESPONSE_STATUS {
  IDLE = 'idle',
  PENDING = 'pending',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed'
}

export interface Filter {
  filter?: string;
  sortOrder?: string;
  page?: number;
  rowsPerPage?: number;
}

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URI
});

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URI,
  prepareHeaders: (headers, { getState }) => {
    const { users } = getState() as RootState;
    const user = users.entities[users.ids[0]];
    if (user) {
      const { token } = user;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
    }

    return headers;
  }
});

export default api;
