import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import LoadingScreen from 'components/LoadingScreen';

import { appRoutes } from './app.routes';
import { authRoutes } from './auth.routes';
import { Routes } from './interfaces';

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || React.Fragment;
        const Layout = route.layout || React.Fragment;
        const Component = route.component;
        return (
          <Route
            // as there is not any key, we are using the key from array
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const notFound: Routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('pages/Fake'))
  }
];

const allRoutes: Routes = [...notFound, ...appRoutes, ...authRoutes];

export default allRoutes;
