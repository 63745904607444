import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import AuthGuard from 'components/AuthGuard';
import { AppLayout } from 'components/Layout';

import { Routes } from './interfaces';

// because of the diffenrent types of return, ESLint not accept it
// eslint-disable-next-line consistent-return
function findRoute(pathValue: string, routes?: Routes): string | string[] | undefined {
  if (routes) {
    let found: string | string[] | undefined;
    let subRouterIndex;
    for (let i = 0; i < routes.length; i += 1) {
      if (routes[i].path === pathValue) {
        return routes[i].path;
      }
      if (routes[i].routes) {
        subRouterIndex = i;
      }
    }
    if (typeof subRouterIndex !== 'undefined') {
      found = findRoute(pathValue, routes[subRouterIndex].routes);
    }
    return found;
  }
}

function routeExists(pathValue: string): boolean {
  return !!findRoute(pathValue, appRoutes);
}

const appRoutes: Routes = [
  {
    path: '/app',
    guard: AuthGuard,
    layout: AppLayout,
    routes: [
      {
        exact: true,
        path: '/app/dashboard',
        component: lazy(() => import('pages/Dashboard'))
      },
      {
        exact: true,
        path: '/app/resource/amcrew',
        component: lazy(() => import('pages/Resource/Amcrew/ListView'))
      },
      {
        exact: true,
        path: ['/app/resource/amcrew/create', '/app/resource/amcrew/:num'],
        component: lazy(() => import('pages/Resource/Amcrew/FormView'))
      },
      {
        exact: true,
        path: '/app/equipment/asset',
        component: lazy(() => import('pages/Equipment/Asset/ListView'))
      },
      {
        exact: true,
        path: ['/app/equipment/asset/create', '/app/equipment/asset/:id'],
        component: lazy(() => import('pages/Equipment/Asset/FormView'))
      },
      {
        exact: true,
        path: '/app/equipment/location',
        component: lazy(() => import('pages/Equipment/Location/ListView'))
      },
      {
        exact: true,
        path: ['/app/equipment/location/create', '/app/equipment/location/:id'],
        component: lazy(() => import('pages/Equipment/Location/FormView'))
      },
      {
        exact: true,
        path: '/app/material/company',
        component: lazy(() => import('pages/Material/Company/ListView'))
      },
      {
        exact: true,
        path: '/app/material/company/create',
        component: lazy(() => import('pages/Material/Company/FormView'))
      },
      {
        exact: true,
        path: '/app/material/company/:id',
        component: lazy(() => import('pages/Material/Company/FormView'))
      },
      {
        exact: true,
        path: '/app/material/item',
        component: lazy(() => import('pages/Material/Item/ListView'))
      },
      {
        exact: true,
        path: '/app/material/item/create',
        component: lazy(() => import('pages/Material/Item/FormView'))
      },
      {
        exact: true,
        path: '/app/material/item/:id',
        component: lazy(() => import('pages/Material/Item/FormView'))
      },
      {
        exact: true,
        path: '/app/material/storeroom',
        component: lazy(() => import('pages/Material/Storeroom/FormView'))
      },
      /*
      {
        exact: true,
        path: '/app/resource/labor',
        component: lazy(() => import('pages/Resource/Labor/ListView')),
      },
      */
      {
        exact: true,
        path: '/app/resource/labor/create',
        component: lazy(() => import('pages/Resource/Labor/FormView'))
      },
      {
        exact: true,
        path: '/app/resource/labor/:id',
        component: lazy(() => import('pages/Resource/Labor/FormView'))
      },
      {
        exact: true,
        path: '/app/resource/labor/create',
        component: lazy(() => import('pages/Resource/Labor/FormView'))
      },
      {
        exact: true,
        path: '/app/resource/labor/:id',
        component: lazy(() => import('pages/Resource/Labor/FormView'))
      },
      {
        exact: true,
        path: '/app/resource/labor/create',
        component: lazy(() => import('pages/Resource/Labor/FormView'))
      },
      {
        exact: true,
        path: '/app/resource/labor/:id',
        component: lazy(() => import('pages/Resource/Labor/FormView'))
      },
      {
        exact: true,
        path: '/app/resource/craft',
        component: lazy(() => import('pages/Resource/Craft/ListView'))
      },
      {
        exact: true,
        path: '/app/resource/craft/create',
        component: lazy(() => import('pages/Resource/Craft/FormView'))
      },
      {
        exact: true,
        path: '/app/resource/craft/:id',
        component: lazy(() => import('pages/Resource/Craft/FormView'))
      },
      {
        exact: true,
        path: '/app/planning/jobplan',
        component: lazy(() => import('pages/Planning/JobPlan/ListView'))
      },
      {
        exact: true,
        path: '/app/planning/jobplan/create',
        component: lazy(() => import('pages/Planning/JobPlan/FormView'))
      },
      {
        exact: true,
        path: '/app/planning/jobplan/:id',
        component: lazy(() => import('pages/Planning/JobPlan/FormView'))
      },
      /*
      {
        exact: true,
        path: '/app/resource/labor',
        component: lazy(() => import('pages/Resource/Labor/ListView')),
      },
      */
      {
        exact: true,
        path: '/app/resource/labor/create',
        component: lazy(() => import('pages/Resource/Labor/FormView'))
      },
      {
        exact: true,
        path: '/app/resource/labor/:id',
        component: lazy(() => import('pages/Resource/Labor/FormView'))
      },
      {
        exact: true,
        path: '/app/planning/preventiveplan',
        component: lazy(() => import('pages/Planning/PreventivePlan/FormView'))
      },
      {
        exact: true,
        path: '/app/planning/preventiveplan',
        component: lazy(() => import('pages/Planning/PreventivePlan/FormView'))
      },
      {
        exact: true,
        path: '/app/resource/labor',
        component: lazy(() => import('pages/Resource/Labor/ListView'))
      },
      {
        exact: true,
        path: '/app/resource/labor',
        component: lazy(() => import('pages/Resource/Labor/ListView'))
      },
      {
        exact: true,
        path: '/app/app4',
        component: lazy(() => import('pages/Fake'))
      },
      {
        exact: true,
        path: '/app/app3',
        component: () => <Redirect to="/app/app4" />
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/dashboard" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export { appRoutes, routeExists };
