import { createApi } from '@reduxjs/toolkit/query/react';
import { Craft } from 'entities/Craft';
import { ApiResponse, baseQuery, Filter } from 'services/api';

export const craftApi = createApi({
  reducerPath: 'craftApi',
  baseQuery,
  tagTypes: ['Crafts', 'CraftByName'],
  endpoints: (builder) => ({
    getCrafts: builder.query<ApiResponse<Craft[]>, Filter>({
      query: (filters) => ({
        url: '/v1/craft/all',
        params: filters
      }),
      providesTags: (result) =>
        result
          ? [
              { type: 'Crafts' as const, id: 'LIST' },
              ...result.data.map(({ craftId }) => ({ type: 'Crafts' as const, craftId }))
            ]
          : ['Crafts']
    }),
    getCraftById: builder.query<ApiResponse<Craft>, string>({
      query: (id) => `/v1/craft?id=${id}`,
      providesTags: (result, error, id) => [{ type: 'Crafts', id }]
    }),
    getCraftByName: builder.query<Craft[], string>({
      query: (name) => {
        const filter: Filter = {
          filter: `[{"field":"craftNum","operation":"like","value":"${name}"}]`
        };
        return {
          url: '/v1/craft/all',
          params: filter
        };
      },
      transformResponse: (result: ApiResponse<Craft[]>) => result.data,
      providesTags: (result) =>
        result ? [...result.map(({ craftNum }) => ({ type: 'CraftByName' as const, craftNum }))] : ['CraftByName']
    }),
    addCraft: builder.mutation<ApiResponse<Craft>, Partial<Craft>>({
      query: (craft) => ({
        url: '/v1/craft',
        method: 'POST',
        body: craft
      }),
      invalidatesTags: [{ type: 'Crafts', id: 'LIST' }]
    }),
    updateCraft: builder.mutation<ApiResponse<Craft>, Partial<Craft>>({
      query: (craft) => ({
        url: '/v1/craft',
        method: 'PUT',
        body: craft
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Crafts', id: arg.craftId },
        { type: 'Crafts', id: 'LIST' }
      ]
    })
  })
});

export const {
  useGetCraftsQuery,
  useGetCraftByIdQuery,
  useGetCraftByNameQuery,
  useAddCraftMutation,
  useUpdateCraftMutation
} = craftApi;
