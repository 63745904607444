import React, { createContext, useState, useCallback } from 'react';

import ConfirmationDialog from 'components/ConfirmationDialog';

import { ConfirmOptions } from './types';

interface ConfirmContextData {
  /**
   * Hook to open a Dialog
   * @param options ConfirmOptions Object to change Dialog and Button props
   */
  useConfirm(options?: ConfirmOptions): Promise<void>;
}
interface ConfirmProviderProps {
  /**
   * Globally Default Options for Confirmations Dialog
   */
  defaultOptions?: ConfirmOptions;
}

export const ConfirmContext = createContext<ConfirmContextData>({} as ConfirmContextData);

const DEFAULT_OPTIONS: ConfirmOptions = {
  title: 'Você tem certeza?',
  description: '',
  customBody: false,
  confirmationText: 'Confirmar',
  cancellationText: 'Cancelar',
  dialogProps: { open: false },
  confirmationButtonProps: {},
  cancellationButtonProps: {}
};

const buildOptions = (defaultOptions: ConfirmOptions, options: ConfirmOptions) => {
  const dialogProps = {
    ...(defaultOptions.dialogProps || DEFAULT_OPTIONS.dialogProps),
    ...(options.dialogProps || { open: false })
  };
  const confirmationButtonProps = {
    ...(defaultOptions.confirmationButtonProps || DEFAULT_OPTIONS.confirmationButtonProps),
    ...(options.confirmationButtonProps || {})
  };
  const cancellationButtonProps = {
    ...(defaultOptions.cancellationButtonProps || DEFAULT_OPTIONS.cancellationButtonProps),
    ...(options.cancellationButtonProps || {})
  };

  return {
    ...DEFAULT_OPTIONS,
    ...defaultOptions,
    ...options,
    dialogProps,
    confirmationButtonProps,
    cancellationButtonProps
  };
};

/**
 * Provider for Dialog with standard or custom props
 * @param props Use `defaultOptions={{}}` shape to get access of custom default options
 * @returns Context of a confirmation dialog
 */
const ConfirmProvider: React.FC<ConfirmProviderProps> = ({
  children,
  defaultOptions = { dialogProps: { open: false } }
}) => {
  const [options, setOptions] = useState<ConfirmOptions>({ ...DEFAULT_OPTIONS, ...defaultOptions });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [resolveReject, setResolveReject] = useState<any>([]);
  const [resolveState, rejectState] = resolveReject;

  const confirm = useCallback(
    (opt: ConfirmOptions = { dialogProps: { open: false } }): Promise<void> =>
      new Promise((resolve, reject) => {
        setOptions(buildOptions(defaultOptions, opt));
        setResolveReject([resolve, reject]);
      }),
    [defaultOptions]
  );

  const handleClose = useCallback(() => {
    setResolveReject([]);
  }, []);

  const handleCancel = useCallback(() => {
    rejectState();
    handleClose();
  }, [rejectState, handleClose]);

  const handleConfirm = useCallback(() => {
    resolveState();
    handleClose();
  }, [resolveState, handleClose]);

  return (
    <>
      <ConfirmContext.Provider value={{ useConfirm: confirm }}>{children}</ConfirmContext.Provider>
      <ConfirmationDialog
        open={resolveReject.length === 2}
        options={options}
        onClose={handleClose}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default ConfirmProvider;
