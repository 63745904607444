import {
  BarChart,
  PieChart,
  LocalOfferOutlined,
  ShoppingBasketOutlined,
  Business,
  PeopleOutline,
  People
} from '@material-ui/icons';
import { Section } from 'components/Layout/AppLayout/NavBar/interface';
import { EngineOutline, OfficeBuildingMarkerOutline, AccountSettingsOutline, HammerScrewdriver } from 'mdi-material-ui';

export const sections: Section[] = [
  {
    subheader: 'Modulo 1',
    items: [
      {
        title: 'App 1',
        icon: BarChart,
        href: '/app/dashboard'
      },
      {
        title: 'App 2',
        icon: PieChart,
        href: '/app/app2'
      }
    ]
  },
  {
    subheader: 'Administração',
    items: [
      {
        title: 'Equipe',
        icon: PeopleOutline,
        href: '/app/resource/amcrew'
      }
    ]
  },
  {
    subheader: 'Equipamento',
    items: [
      {
        title: 'Ativos',
        icon: EngineOutline,
        href: '/app/equipment/asset'
      },
      {
        title: 'Posições',
        icon: LocalOfferOutlined,
        href: '/app/equipment/location'
      }
    ]
  },
  {
    subheader: 'Materiais',
    items: [
      {
        title: 'Almoxarifado',
        icon: OfficeBuildingMarkerOutline,
        href: '/app/material/storeroom'
      },
      {
        title: 'Empresas',
        icon: Business,
        href: '/app/material/company'
      },
      {
        title: 'Item',
        icon: ShoppingBasketOutlined,
        href: '/app/material/item'
      }
    ]
  },
  {
    subheader: 'Recurso',
    items: [
      {
        title: 'Categoria',
        icon: AccountSettingsOutline,
        href: '/app/resource/craft'
      },
      {
        title: 'Mão de Obra',
        icon: People,
        href: '/app/resource/labor/create'
      }
    ]
  },
  {
    subheader: 'Planejamento',
    items: [
      {
        title: 'Planos de Trabalho',
        icon: HammerScrewdriver,
        href: '/app/planning/jobplan'
      },
      {
        title: 'Plano de Preventivo',
        icon: HammerScrewdriver,
        href: '/app/planning/preventiveplan'
      }
    ]
  }
];
