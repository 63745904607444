import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { NotificationsProvider } from 'components/NotificationsProvider';
import { AuthProvider } from 'contexts/authContext';
import ConfirmProvider from 'contexts/ConfirmContext';
import ptBR from 'date-fns/locale/pt-BR';
import allRoutes, { renderRoutes } from 'routes';
import { store } from 'store';
import GlobalStyles from 'styles/GlobalStyles';
import { createTheme } from 'styles/themes';

const theme = createTheme({
  responsiveFontSizes: true,
  theme: 'LightTheme'
});

const App: React.FC = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
        <NotificationsProvider>
          <ConfirmProvider>
            <BrowserRouter>
              <AuthProvider>
                <GlobalStyles />
                {renderRoutes(allRoutes)}
              </AuthProvider>
            </BrowserRouter>
          </ConfirmProvider>
        </NotificationsProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  </Provider>
);

export default App;
