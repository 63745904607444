import { createApi } from '@reduxjs/toolkit/query/react';
import { PreventivePlan } from 'entities/PreventivePlan';
import { ApiResponse, baseQuery, Filter } from 'services/api';

export const preventivePlanApi = createApi({
  reducerPath: 'PreventivePlan',
  baseQuery,
  tagTypes: ['PreventivePlan'],
  endpoints: (builder) => ({
    getPreventivePlans: builder.query<ApiResponse<PreventivePlan[]>, Filter>({
      query: (filters) => ({
        url: '/v1/preventivePlan/all',
        params: filters
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ ppId }) => ({
                type: 'PreventivePlan' as const,
                ppId
              })),
              { type: 'PreventivePlan' as const, id: 'LIST' }
            ]
          : ['PreventivePlan']
    }),
    getPreventivePlanById: builder.query<ApiResponse<PreventivePlan>, string>({
      query: (id) => `/v1/preventivePlan?id=${id}`,
      providesTags: (result, error, id) => [{ type: 'PreventivePlan', id }]
    }),
    addPreventivePlan: builder.mutation<ApiResponse<PreventivePlan>, Partial<PreventivePlan>>({
      query: (preventivePlan) => ({
        url: '/v1/preventivePlan',
        method: 'POST',
        body: preventivePlan
      }),
      invalidatesTags: [{ type: 'PreventivePlan', id: 'LIST' }]
    }),
    updatePreventivePlan: builder.mutation<ApiResponse<PreventivePlan>, Partial<PreventivePlan>>({
      query: (preventivePlan) => ({
        url: '/v1/preventivePlan',
        method: 'PUT',
        body: preventivePlan
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'PreventivePlan', id: arg.ppId },
        { type: 'PreventivePlan', id: 'LIST' }
      ]
    })
  })
});

export const {
  useGetPreventivePlansQuery,
  useAddPreventivePlanMutation,
  useGetPreventivePlanByIdQuery,
  useUpdatePreventivePlanMutation
} = preventivePlanApi;
