import React, { useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { matchPath, useLocation, Link as RouterLink } from 'react-router-dom';

import { Avatar, Box, Divider, Drawer, Hidden, Link, List, ListSubheader, Typography } from '@material-ui/core';
import Logo from 'components/Logo';
import useAuth from 'hooks/useAuth';

import { ChildRoutes, NavItems, Section } from './interface';
import NavItem from './NavItem';
import { useStyles } from './styles';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
  sections: Section[];
}

function renderNavItems({ items, pathname, depth = 0 }: NavItems) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc: unknown[], item) =>
          reduceChildRoutes({
            acc,
            item,
            pathname,
            depth
          }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }: ChildRoutes) {
  let key = '1';

  if (item.title) {
    key = item.title + depth;
  }

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem depth={depth} icon={item.icon} info={item.info} key={key} open={Boolean(open)} title={item.title}>
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(<NavItem depth={depth} href={item.href} icon={item.icon} info={item.info} key={key} title={item.title} />);
  }

  return acc;
}

const NavBar: React.FC<NavBarProps> = ({ onMobileClose, openMobile, sections }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // on this case, with openMobile is on dependence, it will not open the menu
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/app/account">
              <Avatar alt="User" className={classes.avatar} src={user?.avatar} />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link component={RouterLink} to="/app/account" variant="h5" color="textPrimary" underline="none">
              {user?.name}
            </Link>
            <Typography variant="body2" color="textSecondary">
              Your tier?:{' '}
              <Link component={RouterLink} to="/pricing">
                {/* {user.tier} */}
                Nível?
              </Link>
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Divider />
        <Box p={2}>
          <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
            <Typography variant="h6" color="textPrimary">
              Need Help?
            </Typography>
            <Link variant="subtitle1" color="secondary" component={RouterLink} to="/docs">
              Check our docs
            </Link>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default NavBar;
