import { createApi } from '@reduxjs/toolkit/query/react';
import { StatusValues } from 'entities/StatusList';
import { ApiResponse, baseQuery } from 'services/api';

export const statusListApi = createApi({
  reducerPath: 'statusList',
  baseQuery,
  tagTypes: ['StatusLists'],
  endpoints: (builder) => ({
    getStatusByName: builder.query<ApiResponse<StatusValues[]>, string>({
      query: (statusName) => `/v1/statuslist?statusName=${statusName}`,
      keepUnusedDataFor: 3600, // 1h
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ statusListId }) => ({ type: 'StatusLists' as const, statusListId })),
              { type: 'StatusLists' as const, id: 'LIST' }
            ]
          : ['StatusLists']
    })
  })
});

export const { useGetStatusByNameQuery } = statusListApi;

export default statusListApi.reducer;
