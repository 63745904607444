import React from 'react';

import { useStyles } from './styles';

const Logo: React.FC = () => {
  const classes = useStyles();

  return <img alt="logo" src="/static/assets/images/logoHorizontal.png" className={classes.logo} />;
};

export default Logo;
