/* eslint-disable @typescript-eslint/no-explicit-any */
import { MiddlewareAPI, isRejectedWithValue, isFulfilled } from '@reduxjs/toolkit';
import { globallyAvailableNotifications } from 'components/NotificationsProvider';
import { ApiResponse } from 'services/api';

interface WrapperError<T> {
  data: T;
}

/**
 * Log a warning and show a toast!
 */
export const notificationMiddleware =
  (api: MiddlewareAPI) => (next: any) => (action: { error: { data: { message: any } } }) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood,
    // so we're able to utilize these use matchers!
    if (isRejectedWithValue(action)) {
      if (isApiResponseError(action.payload)) {
        const { messages } = action.payload.data;
        messages.forEach(({ text, type }) => {
          globallyAvailableNotifications.enqueueNotification(text, type);
        });
      }
    } else if (isFulfilled(action)) {
      if (isApiResponseSuccess(action.payload)) {
        const { messages } = action.payload;
        messages.forEach(({ text, type }) => {
          globallyAvailableNotifications.enqueueNotification(text, type);
        });
      }
    } else if (action.error) {
      if (action.error.data) {
        globallyAvailableNotifications.enqueueErrorNotification(action.error.data.message);
      }
      // use this only if you need during development
      // else {
      //   console.error(action);
      // }
    }

    return next(action);
  };

function isApiResponseError(args: any): args is WrapperError<ApiResponse> {
  return (args as WrapperError<ApiResponse>).data?.success !== undefined;
}

function isApiResponseSuccess(args: any): args is ApiResponse {
  return (args as ApiResponse).success !== undefined;
}
