import { createApi } from '@reduxjs/toolkit/query/react';
import { AssetHistory } from 'entities/AssetHistory';
import { ApiResponse, baseQuery } from 'services/api';

export const assetHistoryApi = createApi({
  reducerPath: 'assetHistories',
  baseQuery,
  tagTypes: ['AssetHistories'],
  endpoints: (builder) => ({
    getAssetHistoryById: builder.query<ApiResponse<AssetHistory[]>, string>({
      query: (id) => `/v1/assethistory/assetall?id=${id}`,
      providesTags: (result, error, id) => [{ type: 'AssetHistories', id }]
    }),
    addAssetHistory: builder.mutation<ApiResponse<AssetHistory>, Partial<AssetHistory>>({
      query: (assethistory) => ({
        url: '/v1/assethistory',
        method: 'POST',
        body: assethistory
      }),
      invalidatesTags: [{ type: 'AssetHistories', id: 'LIST' }]
    })
  })
});

export const { useGetAssetHistoryByIdQuery, useAddAssetHistoryMutation } = assetHistoryApi;

export default assetHistoryApi.reducer;
