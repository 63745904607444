import { createApi } from '@reduxjs/toolkit/query/react';
import { Location } from 'entities/Location';
import { ApiResponse, baseQuery, Filter } from 'services/api';

export const locationApi = createApi({
  reducerPath: 'locations',
  baseQuery,
  tagTypes: ['Locations'],
  endpoints: (builder) => ({
    getLocations: builder.query<ApiResponse<Location[]>, Filter>({
      query: (filters) => ({
        url: '/v1/location/all',
        params: filters
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ locationId }) => ({ type: 'Locations' as const, locationId })),
              { type: 'Locations' as const, id: 'LIST' }
            ]
          : ['Locations']
    }),
    getLocationById: builder.query<ApiResponse<Location>, string>({
      query: (id) => `/v1/location?id=${id}`,
      providesTags: (result, error, id) => [{ type: 'Locations', id }]
    }),
    getLocationChildren: builder.query<ApiResponse<Location[]>, { locationNum: string; filters: Filter }>({
      query: ({ locationNum, filters }) => ({
        url: `/v1/location/children?locationNum=${locationNum}`,
        params: filters
      })
    }),
    addLocation: builder.mutation<ApiResponse<Location>, Partial<Location>>({
      query: (location) => ({
        url: '/v1/location',
        method: 'POST',
        body: location
      }),
      invalidatesTags: [{ type: 'Locations', id: 'LIST' }]
    }),
    updateLocation: builder.mutation<ApiResponse<Location>, Partial<Location>>({
      query: (location) => ({
        url: 'v1/location',
        method: 'PUT',
        body: location
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Locations', id: arg.locationId },
        { type: 'Locations', id: 'LIST' }
      ]
    })
  })
});

export const {
  useGetLocationsQuery,
  useGetLocationByIdQuery,
  useGetLocationChildrenQuery,
  useAddLocationMutation,
  useUpdateLocationMutation
} = locationApi;

export default locationApi.reducer;
